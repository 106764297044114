import Content from "components/Layout/Content/Content.js";
import FlexBoxWrapper from "components/Layout/Wrappers/FlexBoxWrapper.js";
import { AuthContext } from "contexts/AuthContext";
import { parse } from "query-string";
import React, { useContext, useState } from "react";
import styles from "./LoginWithToken.module.scss";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import useAsyncEffect from "use-async-effect";

const LoginWithToken = props => {
	const { user, signInWithToken } = useContext(AuthContext);
	const [loginFailed, setLoginFailed] = useState(false);
	const history = useHistory();

	useAsyncEffect(async () => {
		if (!user) {
			const params = parse(history.location.search);
			if (params.token) {
				try {
					await signInWithToken(params.token);
				} catch (error) {
					setLoginFailed(true);
				}
			} else {
				setLoginFailed(true);
			}
		}
	}, [history, user]);

	return (
		<Content minWidth={0} minHeight={500}>
			<FlexBoxWrapper justifyContent={'center'}>
				{loginFailed ? (
					<div className={styles.Error}>Something went terribly wrong, you cannot join the Party...</div>
				) : (
					<div className={styles.LoggingIn}>Loggin In</div>
				)}
			</FlexBoxWrapper>
		</Content>
	);
};

LoginWithToken.propTypes = {};

export default LoginWithToken;
