import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import styles from "./Arm.module.scss";

const Arm = ({side, isUp=false, bodyPartBaseStyle}) => {
    const classes = classNames(bodyPartBaseStyle, styles['Arm-' + side], {[styles.up]:isUp});

    return (
        <div className={classes}/>
    );
};

Arm.propTypes = {

};

export default Arm;
