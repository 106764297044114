import { advanceProgress } from "api/loaders/userProgressLoaders.js";
import Content from "components/Layout/Content/Content.js";
import FlexBoxWrapper from "components/Layout/Wrappers/FlexBoxWrapper.js";
import Modal from "components/Modal/Modal.js";
import TimerStateful from "components/TimerStateful/TimerStateful.js";
import FatHeadline from "components/Typography/FatHeadline/FatHeadline.js";
import { AuthContext } from "contexts/AuthContext";
import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import DatePicker from "react-datepicker";
import CookieConsent from "views/CookieRiddle/CookieConsent/CookieConsent.js";
import styles from "./CookieRiddle.module.scss";
import classNames from "classnames";
import { isSameDay } from "date-fns";
require( "./DatepickerOverwrites.css");

const CookieRiddle = props => {
	const [cookies, setCookie, removeCookie] = useCookies();
	const cookieName = "India's favorite cookie";
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [cookieIsSet, setCookieIsSet] = useState(false);
    const [showConsent, setShowConsent] = useState(false);
    const [showShowConsentButton, setShowShowConsentButton] = useState(false);
    const [wrongAnswerGiven, setWrongAnswerGiven] = useState(false);
	const { updateUserProgress } = useContext(AuthContext);

	const cookieDate = useMemo(() => {
		return new Date("2022-01-17");
	}, []);

	useEffect(() => {
		removeCookie(cookieName);
	}, []);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setShowConsent(true);
        }, 5000);
        return () => {
            clearInterval(timeOut);
        };
    }, []);

	const handleDatePickerChange = date => {
		if (cookieIsSet && isSameDay(date, cookieDate)) {
			setShowSuccessModal(true);
		}
		else{
            setWrongAnswerGiven(true);
            setTimeout(() => {
                setWrongAnswerGiven(false);
            }, 2000);
        }
	};

	const handleModalClose = async () => {
		const newProgress = await advanceProgress(4);
		updateUserProgress(newProgress.data);
	};

	const handleClickCookieConsent = useCallback(() => {
		setCookie(cookieName, "YouAreVeryClose", { expires: cookieDate });
		setCookieIsSet(true);
        setShowConsent(false);
        setShowShowConsentButton(true);
	}, []);
	const handleShowConsentClick = useCallback(()=>{
	    setShowConsent(true)
    });

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<button className={styles.DatePickerInput} onClick={onClick} ref={ref}>
			{value}
		</button>
	));

    const cookieClassNames = useMemo(() => {
        return classNames(styles.Cookie, wrongAnswerGiven ? styles.CookieSad : styles.CookieHappy)
    }, [wrongAnswerGiven]);


    return (
		<>
			<Content>
				<FlexBoxWrapper justifyContent={"center"} direction={"column"}>
					<FatHeadline>Der Lieblinkskeks</FatHeadline>
                    <p>...ist verschwunden!!! Andererseits - wahrscheinlich war er eh schon abgelaufen...</p>
					<div className={styles.CookieWrapper}>
                        {cookieIsSet && <div className={cookieClassNames}/>}
						<DatePicker
							onChange={handleDatePickerChange}
							customInput={<ExampleCustomInput />}
						/>
					</div>
				</FlexBoxWrapper>
			</Content>
			{cookieDate && <CookieConsent expiresAt={cookieDate} onClickConsent={handleClickCookieConsent} onClickShowConsent={handleShowConsentClick} showConsent={showConsent} showShowConsentButton={showShowConsentButton}/>}
            <TimerStateful/>
            <Modal show={showSuccessModal} onClose={handleModalClose}>
                Sorry, das war echt nerdig. Ab jetzt wird's entspannter. Vielleicht.
            </Modal>
		</>
	);
};

CookieRiddle.propTypes = {};

export default CookieRiddle;
