import Button from "components/Button";
import React from "react";
import styles from "./CookieConsent.module.scss";
import classNames from "classnames";
const CookieConsent = ({ onClickConsent, onClickShowConsent, showConsent, showShowConsentButton }) => {
	const wrapperClasses = classNames(styles.CookieConsentWrapper, { [styles.show]: showConsent });

    return (
		<>
			<div className={wrapperClasses}>
				<div>
					Um das Seitenerlebnis für sie noch schöner zu gestalten nutzen wir Cookies um ihre wichtigsten Daten
					zu tracken. Weitere Informationen zu unseren Datenschutzrichtlinien finden sie{" "}
					<a href={"https://developer.chrome.com/docs/devtools/storage/cookies/"} target={"_blank"}>
						hier
					</a>{" "}
					oder{" "}
					<a
						href={
							"https://www.google.com/search?sxsrf=ALeKk00DcI3RjqpqaAcdcaMro0uD8m31uA:1621583442400&q=cookie+expiration+date&sa=X&ved=2ahUKEwjZ1rqIpdrwAhWRGewKHRDQA_YQ7xYoAHoECAEQNg&biw=1556&bih=960&dpr=2"
						}
						target={"_blank"}
					>
						hier
					</a>
					.
				</div>
				<Button.Solid onClick={onClickConsent}>Ich gebe mein Einverständnis</Button.Solid>
			</div>
            {showShowConsentButton &&<div className={styles.showCookieButton} onClick={onClickShowConsent}>
				Datenschutz Einstellungen
			</div>}
		</>
	);
};

CookieConsent.propTypes = {};

export default CookieConsent;
