import React from 'react';
import styles from './Timer.module.scss';

const Timer = ({hours, minutes, seconds, separator=':', showHours=true}) => {
    minutes = minutes.toString().padStart(2,"0");
    seconds = seconds.toString().padStart(2,"0");
    hours = hours.toString().padStart(2,"0");
    return (
        <div className={styles.Timer}>
            {showHours && (<><span>{hours}</span><span>{separator}</span></>)}
            <span>{minutes}</span><span>{separator}</span>
            <span>{seconds}</span>
        </div>
    );
};

Timer.propTypes = {

};

export default Timer;
