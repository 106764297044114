import { advanceProgress, resetProgress } from "api/loaders/userProgressLoaders.js";
import Button from "components/Button";
import { ROLE_RIDDLER } from "constants/roles.js";
import { AuthContext } from "contexts/AuthContext";
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import Logout from "assets/images/icons/icon_logout@2x.png";
import { getCurrentStep } from "utils/userProgressUtils.js";
import styles from "./Header.module.scss";
import { HOME_ROUTE } from "constants/appRoutes.js";

const Header = props => {
	const { user, signOut } = useContext(AuthContext);
	const history = useHistory();
	const handleLogout = async () => {
		signOut();
		history.push(HOME_ROUTE);
	};

	const handleResetProgress = async () => {
		await resetProgress();
		window.location.href = HOME_ROUTE;
	};

	const handleSkipRiddleClick = async () => {
		const currentStep = getCurrentStep(user.progress);
		await advanceProgress(currentStep.current_step_id + 1);
		window.location.href = HOME_ROUTE;
	};

	return (
		<>
            {user && user.name !== 'India' && user.role === ROLE_RIDDLER && <div className={styles.UserWidget}>
				<div>Dev: </div>
				{user && user.role === ROLE_RIDDLER && (
					<>
						<Button.Solid onClick={handleSkipRiddleClick}>Skip Riddle</Button.Solid>npm start
						<Button.Solid onClick={handleResetProgress}>Reset progress</Button.Solid>
					</>
				)}
				<Button.Solid onClick={handleLogout}>Logout</Button.Solid>
			</div>}
		</>
	);
};

Header.propTypes = {};

export default Header;
