import { HOME_ROUTE } from "constants/appRoutes.js";
import React from "react";
import styles from "./Layout.module.scss";
import {useLocation} from "react-router-dom";
import Header from "./Header/Header";
const Layout = props => {
	const location = useLocation();
	return (
		<>
            {location.pathname !== HOME_ROUTE && <Header />}
			{props.children}
		</>
	);
};

export default Layout;
