import React, { useCallback, useEffect, useState } from "react";
import {
    login,
    logout,
    getAuthenticatedUser as getAuthenticatedUserCall,
    loginWithToken,
} from "api/loaders/authLoaders.js";
import { deleteTokens, setTokens, ACCESS_TOKEN_KEY } from "services/tokenService";
import useAsyncEffect from "use-async-effect";

import { AuthContext } from "./AuthContext.js";

export const AuthContextProvider = ({ accessToken, children }) => {
	const [user, setUser] = useState();
	const [isCheckingAuthStatus, setIsCheckingAuthStatus] = useState(true);

	const signIn = async (username, password) => {
		try {
			await login(username, password);
			await getAuthenticatedUser();
		} catch (error) {
			throw new Error("Login failed");
		}
	};

    const signInWithToken = async (token) => {
        try {
            await loginWithToken(token);
            await getAuthenticatedUser();
        } catch (error) {
            throw new Error("Login failed");
        }
    };

	const signOut = async () => {
		try {
			await logout();
		} catch (error) {}
		setUser();
	};


    const updateUserProgress = useCallback((newProgress) => {
        const updatedUserData = {...user};
        updatedUserData.progress = newProgress;
        setUser(updatedUserData);
    }, [user]);


	const getAuthenticatedUser = async () => {
		try {
			const userResponse = await getAuthenticatedUserCall();
			setUser(userResponse.data);
		} catch (error) {
			// console.log(error);
		}
	};

	useAsyncEffect(async () => {
		setIsCheckingAuthStatus(true);

        try {
            await getAuthenticatedUser();
        } catch (error) {
            // console.log(error);
        }

		setIsCheckingAuthStatus(false);
	}, []);

	return (
		<AuthContext.Provider
			value={{
				user,
				isCheckingAuthStatus,
                updateUserProgress,
				signIn,
                signInWithToken,
				signOut,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
