import { useWindowSize } from "@react-hook/window-size";
import Content from "components/Layout/Content/Content.js";
import FlexBoxWrapper from "components/Layout/Wrappers/FlexBoxWrapper.js";
import FatHeadline from "components/Typography/FatHeadline/FatHeadline.js";
import React from "react";
import PropTypes from "prop-types";
import ReactConfetti from "react-confetti";
import styles from "./FinalScreen.module.scss";

const FinalScreen = props => {
    const [width, height] = useWindowSize();

    const colors=["#15786d", "#f57963", "#f9d1a3", "#4f9480"];
    return (
        <>
        <ReactConfetti width={width} height={height} colors={colors} numberOfPieces={200}/>
        <Content>
            <FlexBoxWrapper direction={'column'} justifyContent={'center'}>
                <FatHeadline>Herzlichen Glückwunsch!</FatHeadline>
                <div className={styles.India}/>
                <p style={{textAlign:'center', marginBottom:"0"}}>Geschafft! Sehr gut durchgehalten. Mit den Rätseln ist jetzt leider Schluß, dafür gibt's aber natürlich noch ein schmuckloses Geldgeschenk!<br/>Du solltest Dich deshalb dringend mit uns in Verbindung setzen, damit wir die Überweisung in die Wege leiten können. <br/>Die Domain "indialeier.com" gehört natürlich auch Dir. Falls Du ne Website brauchst, sag Bescheid :) </p>
                <p style={{textAlign:'center', marginBottom:"0", marginTop:"0.5rem"}}>Lass Dich ordentlich feiern,</p>
                <p style={{textAlign:'center', marginBottom:"0", marginTop:"0.5rem"}}>Jessie, Paul, Marlene und Elli</p>
            </FlexBoxWrapper>
        </Content>
            </>
    );
};

FinalScreen.propTypes = {

};

export default FinalScreen;
