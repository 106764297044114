import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Box.module.scss";

const Box = ({ color = "neutral", children, ...other }) => {
	return (
		<div className={classNames(styles.Box, styles[color])} {...other}>
			{children}
		</div>
	);
};

Box.propTypes = {};

export default Box;
