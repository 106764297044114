import { advanceProgress } from "api/loaders/userProgressLoaders.js";
import Content from "components/Layout/Content/Content.js";
import FlexBoxWrapper from "components/Layout/Wrappers/FlexBoxWrapper.js";
import Modal from "components/Modal/Modal.js";
import FatHeadline from "components/Typography/FatHeadline/FatHeadline.js";
import { APP_URL, PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from "constants/env.js";
import { AuthContext } from "contexts/AuthContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import { GIFT_GLASS_NAME, GIFT_HAT_NAME, GIFT_PRESENT_NAME } from "views/PartyRiddle/India/constants.js";
import India from "views/PartyRiddle/India/India.js";
import {
	ALL_GUESTS_ARRIVED_MESSAGE,
	GUEST_LEFT_MESSAGE,
	SOME_GUESTS_ARRIVED_MESSAGE,
} from "views/PartyRiddle/messages.js";

Pusher.Runtime.createXHR = function () {
	var xhr = new XMLHttpRequest();
	xhr.withCredentials = true;
	return xhr;
};

let initState = [
	{ userId: "asd", presentName: GIFT_GLASS_NAME },
	{ userId: "asd", presentName: GIFT_HAT_NAME },
	{ userId: "asd", presentName: GIFT_PRESENT_NAME },
];

initState = [];

const maxPartyGuests = 3;
const PartyRiddle = props => {
	const { user, updateUserProgress } = useContext(AuthContext);
	const [partyGuests, setPartyGuests] = useState(initState);
	const [partyStarted, setPartyStarted] = useState(false);
	const [partyStatusMessage, setPartyStatusMessage] = useState(null);
	const [partySuspended, setPartySuspended] = useState(false);
    const [channel, setChannel] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

	useEffect(() => {
		// Pusher.logToConsole = true;
		//
		const pusher = new Pusher(PUSHER_APP_KEY, {
			cluster: PUSHER_APP_CLUSTER,
			authEndpoint: APP_URL + "api/broadcasting/auth",
		});

		const channel = pusher.subscribe("private-friends-help-" + user.id);

		channel.bind("client-send-gift", giftData => {
			setPartyGuests(prevState => {
				if (!prevState.find(friend => friend.userId === giftData.userId)) {
					return [...prevState, giftData];
				} else {
					return prevState.map(friend => {
						if (friend.userId === giftData.userId) {
							return giftData;
						}
						return friend;
					});
				}
			});
		});

		channel.bind("client-user-joined", userData => {
			setPartyGuests(prevState => {
				const userExists = prevState.find(friend => friend.userId === userData.userId);
				if (!userExists && prevState.length >= maxPartyGuests) {
					channel.trigger("client-channel-full", { userId: userData.userId });
					return prevState;
				} else if (!userExists) {
					return [...prevState, { userId: userData.userId, presentName: null }];
				} else {
					return prevState.map(friend => {
						if (friend.userId === userData.userId) {
							return { userId: userData.userId, presentName: null };
						}
						return friend;
					});
				}
			});
		});

		channel.bind("client-user-left", userData => {
			setPartyGuests(prevState => {
				const existingUserIndex = prevState.findIndex(friend => friend.userId === userData.userId);
				if (existingUserIndex === -1) {
					return prevState;
				}

				return prevState.splice(existingUserIndex, 1);
			});
		});

		channel.bind("pusher:subscription_succeeded", () => {
			channel.trigger("client-host-joined", []);
            setChannel(channel)
		});
	}, [setPartyGuests, user.id]);

	useEffect(() => {
	    if(partyGuests.length === 0){
            setPartyStatusMessage(null);
        }
	    else if (partyGuests.length === maxPartyGuests) {
			setPartyStarted(true);
			setPartyStatusMessage(ALL_GUESTS_ARRIVED_MESSAGE);
			if(channel){
                channel.trigger("client-party-status", {partyStarted: true})
            }
		} else if (partyGuests.length < maxPartyGuests) {
			let message = SOME_GUESTS_ARRIVED_MESSAGE;

			if (partyStarted) {
				setPartySuspended(true);
			}

			if (partyStarted || partySuspended) {
				message = GUEST_LEFT_MESSAGE;
			}

			setPartyStatusMessage(message);
			setPartyStarted(false);
            if(channel){
                channel.trigger("client-party-status", {partyStarted: false})
            }
		} else {
			throw Error("Too many guests!!!");
		}
	}, [partyGuests.length, setPartyStarted, partySuspended, partyStarted, channel]);

	const handleRiddleSolved=async ()=>{
        setTimeout(async()=>{
            setShowSuccessModal(true)
        },2000)

    };

	const handleModalClose = async()=>{
        const newProgress = await advanceProgress(6);
        updateUserProgress(newProgress.data);

    };

	return (
		<Content>
			<FatHeadline>Zeit zum Feiern</FatHeadline>
            <p>Genug ausgeruht! Jetzt ist aber langsam mal Zeit für Party und Geschenke. Aber irgendwie fehlen die Gäste. Du solltest schnellstens ein paar Einladungen (ver)-teilen, sonst kommt hier ja nie Partystimmung auf...</p>
			<FlexBoxWrapper justifyContent={"center"} direction={'column'}>
				<India partyGuests={partyGuests} maxNumberOfGuests={maxPartyGuests} onRiddleSolved={handleRiddleSolved}/>
                <p>{partyStatusMessage}</p>
			</FlexBoxWrapper>
            <Modal show={showSuccessModal} onClose={handleModalClose} buttonText={"Her damit!"}>
                Und auch das letzte Rätsel wäre geschafft. Jetzt wird es aber Zeit für richtige Geschenke.
            </Modal>
		</Content>
	);
};

export default PartyRiddle;
