import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import styles from "./Friend.module.scss";

const Friend = ({ position, giftName = null }) => {
	return (
		<>
			<div className={styles["Friend-" + position]} />
			{giftName && <div className={styles[giftName]} />}
		</>
	);
};

Friend.propTypes = {};

export default Friend;
