import Button from "components/Button";
import Content from "components/Layout/Content/Content.js";
import FlexBoxWrapper from "components/Layout/Wrappers/FlexBoxWrapper.js";
import React, { useState } from "react";

import Login from "views/Home/Login/Login.js";
import styles from "./Home.module.scss";

const Home = props => {
	const [showLogin, setShowLogin] = useState(false);
    const [showQuestions, setShowQuestions] = useState(false);

	const showLoginScreen = () => {
		setShowLogin(true);
        setShowQuestions(false);
	};

	const handleShowQuestions = ()=>{
        setShowLogin(false);
        setShowQuestions(true);
    };
	return (
		<Content withInnerWrapper={false} minWidth={0} minHeight={0}>
			<FlexBoxWrapper justifyContent={"center"}>
                {!showLogin && !showQuestions &&<div className={styles.WelcomeText} onClick={showLoginScreen}>ALOHA</div>}
                {showLogin && !showQuestions &&  <Login onShowQuestionsClick={handleShowQuestions}/>}
                {showQuestions && (<div className={styles.Questions}>
                    <h2>Dein Benutzername</h2>
                    <p>ist Dein Vorname@indialeier.com</p>
                    <h2>Dein Passwort</h2>
                    <p>setzt sich aus den folgenden Teilen zusammen <br/>(nur Kleinbuchstaben, alles zusammen):</p>
                    <ol>
                        <li>Die Straße, in der Du zuletzt in Hamburg gewohnt hast.</li>
                        <li>Der Name des ersten Hundes Deiner Mudder.</li>
                        <li>Der Vorname der Frau des Cousins zweiten Grades (großmütterlicherseits) des Bruders der Tante (mütterlicherseits) von deinem Vater ihm sein Sohn.</li>
                    </ol>
                    <FlexBoxWrapper justifyContent={"center"} direction={"column"} style={{ marginTop: "1rem" }}>
                        <Button.Solid onClick={showLoginScreen}>Alles klar</Button.Solid>
                    </FlexBoxWrapper>
                </div>)}
			</FlexBoxWrapper>

		</Content>
	);
};

Home.propTypes = {};

export default Home;
