import { getCsrfToken } from "api/loaders/authLoaders.js";
import classNames from "classnames";
import Button from "components/Button";
import Form from "components/Form/Form.js";
import Input from "components/Form/Input/Input.js";
import Box from "components/Layout/Box";
import FlexBoxWrapper from "components/Layout/Wrappers/FlexBoxWrapper.js";
import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./Login.module.scss";

let defaultValues = { username: "test@test.com", password: "test" };
defaultValues = {};
const Login = ({ className, onShowQuestionsClick }) => {
	const { signIn } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [loginFailed, setLoginFailed] = useState(false);
	const { register, handleSubmit } = useForm({ defaultValues: defaultValues });

	const onSubmit = async (data, event) => {
		await getCsrfToken();

		try {
			setLoading(true);
			await signIn(data.username, data.password);


		} catch (error) {
			setLoginFailed(true);
			setLoading(false);
		}
	};

	return (
		<div className={classNames(styles.LoginFormWrapper, className)}>
            <h1 style={{fontWeight:'normal', marginBottom:'0.5rem'}}>LOGIN</h1>
			<Form dark={false} onSubmit={handleSubmit(onSubmit)}>
				<Input label={"Username"} {...register("username")} />
				<Input label={"Password"} type={"password"} {...register("password")} />
				<div className={styles.ForgotPassWordLink} onClick={onShowQuestionsClick}>forgot password?</div>
				<FlexBoxWrapper justifyContent={"center"} direction={"column"} style={{ marginTop: "1rem" }}>
					{loginFailed && (
						<Box color={"warning"} style={{ marginBottom: "1rem", textAlign: "center" }}>
							Login failed
						</Box>
					)}
					<Button.Solid type={"submit"} loading={loading} style={{ width: "10rem" }}>
						<div>Submit login</div>
					</Button.Solid>
				</FlexBoxWrapper>
			</Form>
		</div>
	);
};

Login.propTypes = {};

export default Login;
