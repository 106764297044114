import React from "react";
import PropTypes from "prop-types";
import styles from "./FatHeadline.module.scss";

const FatHeadline = props => {
    return (
        <div className={styles.FatHeadline}>
            {props.children}
        </div>
    );
};

FatHeadline.propTypes = {

};

export default FatHeadline;
