import { advanceProgress } from "api/loaders/userProgressLoaders.js";
import classNames from "classnames";
import Content from "components/Layout/Content/Content.js";
import FlexBoxWrapper from "components/Layout/Wrappers/FlexBoxWrapper.js";
import Modal from "components/Modal/Modal.js";
import Timer from "components/Timer/Timer.js";
import FatHeadline from "components/Typography/FatHeadline/FatHeadline.js";
import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import TicTacToeWrapper from "views/TimeOutRiddle/TicTacToe/components/TicTacToeWrapper.jsx";
import styles from "./TimeOutRiddle.module.scss";
import { useStopwatch } from "react-timer-hook";
import useTabVisibility from "use-tab-visibility";
const TimeOutRiddle = props => {
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const { updateUserProgress } = useContext(AuthContext);
	const { seconds, minutes, hours, start, pause, reset } = useStopwatch({ autoStart: true });
	const [winner, setWinner] = useState(0);
	const [scoreIndia, setScoreIndia] = useState(0);
	const [scoreTiki, setScoreTiki] = useState(0);

	const { visible } = useTabVisibility();

	useEffect(() => {
		if (!visible) {
			pause();
		} else {
			start();
		}
	}, [visible]);

	useEffect(() => {
		if(minutes=== 5 && seconds===22){
		    setShowSuccessModal(true);
		    pause()
		}
	}, [minutes, seconds]);

	const resetHandler = () => {
		reset(new Date());
	};

	const handleModalClose = async () => {
		const newProgress = await advanceProgress(5);
		updateUserProgress(newProgress.data);
	};

	const updateScore = winner => {
		if (winner === 2) {
			setScoreTiki(prevScore => prevScore + 1);
		}
		else if(winner === 1){
            setScoreIndia(prevScore => prevScore + 1);
        }

		setWinner(winner);
	};

	const moodClassIndia = useMemo(() => {
		if (winner === 2 || winner === -1) {
			return styles.badMood;
		}
		return null;
	}, [winner]);

	const moodClassTiki = useMemo(() => {
		if (winner === 2) {
			return styles.winner;
		}
		return null;
	}, [winner]);

	return (
		<Content>
			<Timer hours={hours} minutes={minutes} seconds={seconds} showHours={false}/>
			<FatHeadline>Beat the Tiki</FatHeadline>
            <p>Nachdem der Keks ja anscheinend noch ganz in Ordnung war, stehst Du nun vor einem ganz anderen Problem. So'n dickrockiger Tiki-Typ versperrt den Weg zum nächsten Rätsel und fordert Dich zu einer Runde Tic Tac Toe heraus. Er ist ein starker Gegner, also verzage nicht. Mit der richtigen Strategie und der nötigen Ruhe wirst Du ihn schon schlagen.</p>
			<FlexBoxWrapper justifyContent={"center"}>
				<div className={classNames(styles.IndiaCharacter, moodClassIndia)}>
					<div className={styles.IndiaIdle} />
					<div className={styles.IndiaLoser} />
				</div>
				<div className={styles.GameWrapper}>
					<div className={styles.ScoreWrapper}>
						<div>{scoreIndia}</div>:<div>{scoreTiki}</div>
					</div>
					<TicTacToeWrapper resetTimer={resetHandler} updateScore={updateScore} disablePlayButton={winner === 0} />
				</div>
				<div className={classNames(styles.TikiCharacter, moodClassTiki)}>
					<div className={styles.TikiIdle} />
					<div className={styles.TikiWinner} />
				</div>
			</FlexBoxWrapper>
            <Modal show={showSuccessModal} onClose={handleModalClose}>
                Entschuldige, dass ich Dich noch mal auf Deinen Geburtstag habe warten lassen. Dafür kannst Du aber jetzt feiern gehen.
            </Modal>
		</Content>
	);
};

TimeOutRiddle.propTypes = {};

export default TimeOutRiddle;
