import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import Button from "components/Button";
import styles from "./Modal.module.scss";

const Modal = ({ show, onClose, closeOnEscOrOutsideClick = true, children, buttonText="Weiter geht's" }) => {
	const closeOnOutsideClick = () => {
		if (closeOnEscOrOutsideClick) {
			onClose();
		}
	};

	const closeOnEscapeClick = (event) => {
		if ((event.keyCode || event.charCode) === 27) {
			onClose();
		}
	};

	useEffect(() => {
		if (closeOnEscOrOutsideClick) {
			document.body.addEventListener("keydown", closeOnEscapeClick);
			return () => {
				document.body.removeEventListener("keydown", closeOnEscapeClick);
			};
		}
	}, [closeOnEscOrOutsideClick]);


	return ReactDOM.createPortal(
		<CSSTransition
			in={show}
			unmountOnExit={true}
			timeout={{ enter: 0, exit: 300 }}
			classNames={{
				enterDone: styles.enterDone,
				exitDone: styles.exit,
			}}
		>
			<div className={styles.Modal} onClick={closeOnOutsideClick}>
				<div className={styles.ModalContent} onClick={(event) => event.stopPropagation()}>
					<div className={styles.ModalHeader}>
						Yeah!
					</div>
					<div className={styles.ModalBody}>{children}</div>
					<div className={styles.ModalFooter}>
						<Button.Solid onClick={onClose}>{buttonText}</Button.Solid>
					</div>
				</div>
			</div>
		</CSSTransition>,
		document.getElementById("root")
	);
};

Modal.propTypes = {};

export default Modal;
