import {
	COOKIE_RIDDLE_ROUTE,
	FINAL_SCREEN_ROUTE,
	FRIENDS_RIDDLE_ROUTE,
	TIMEOUT_RIDDLE_ROUTE,
	URL_RIDDLE_ROUTE,
	X_RIDDLE_ROUTE,
} from "constants/appRoutes.js";

export const progressOrder = [
	{ stepId: 1, route: URL_RIDDLE_ROUTE },
	{ stepId: 2, route: X_RIDDLE_ROUTE },
	{ stepId: 3, route: COOKIE_RIDDLE_ROUTE },
	{ stepId: 4, route: TIMEOUT_RIDDLE_ROUTE },
	{ stepId: 5, route: FRIENDS_RIDDLE_ROUTE },
    { stepId: 6, route: FINAL_SCREEN_ROUTE },
];

export const getRouteByStepId = stepId => {
	const step = progressOrder.find(stepData => stepData.stepId === stepId);
	if (!step) {
		throw new Error(`Invalid step id: ${stepId}`);
	}
	return step.route;
};

export const getRedirectPathFromUserProgress = (progress = []) => {
	if (progress.length === 0) {
		return getRouteByStepId(1);
	}

	const lastStep = getCurrentStep(progress);

	if (!lastStep.completed) {
		return getRouteByStepId(lastStep.current_step_id);
	} else {
		return FINAL_SCREEN_ROUTE;
	}
};

export const getCurrentStep = progress => {
	return [...progress]
		.sort((a, b) => {
			return a.current_step_id - b.current_step_id;
		})
		.pop();
};
