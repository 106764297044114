import axiosApi from "api/axiosApi.js";
import {
	loginEndpoint,
	logoutEndpoint,
	getAuthenticatedUserEndpoint,
	getCsrfTokenEndpoint,
	loginWithTokenEndpoint,
} from "api/endpoints/authEndpoints.js";

export const getCsrfToken = () => axiosApi.get(getCsrfTokenEndpoint);
export const login = (username, password) => axiosApi.post(loginEndpoint, { email: username, password: password });
export const loginWithToken = token => axiosApi.post(loginWithTokenEndpoint, { token: token });
export const logout = () => axiosApi.get(logoutEndpoint);
export const getAuthenticatedUser = () => axiosApi.get(getAuthenticatedUserEndpoint);
