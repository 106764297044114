import { advanceProgress } from "api/loaders/userProgressLoaders.js";
import { ROLE_PARTY_GUEST, ROLE_RIDDLER } from "constants/roles.js";
import { AuthContext } from "contexts/AuthContext";
import { parse } from "query-string";
import React, { useContext } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Layout from "components/Layout/Layout.js";
import {
    COOKIE_RIDDLE_ROUTE, FINAL_SCREEN_ROUTE,
    FRIENDS_ASSIST_ROUTE,
    FRIENDS_RIDDLE_ROUTE,
    HOME_ROUTE,
    LOGIN_WITH_TOKEN_ROUTE,
    TIMEOUT_RIDDLE_ROUTE,
    URL_RIDDLE_ROUTE,
    X_RIDDLE_ROUTE,
} from "constants/appRoutes.js";
import useAsyncEffect from "use-async-effect";
import { getCurrentStep, getRouteByStepId } from "utils/userProgressUtils.js";
import CookieRiddle from "views/CookieRiddle/CookieRiddle.js";
import FinalScreen from "views/FinalScreen/FinalScreen.js";
import PartyGuest from "views/PartyGuest/PartyGuest.js";
import PartyRiddle from "views/PartyRiddle/PartyRiddle.js";
import Home from "views/Home/Home.js";
import LoginWithToken from "views/LoginWithToken/LoginWithToken.js";
import TimeOutRiddle from "views/TimeOutRiddle/TimeOutRiddle.js";
import Welcome from "views/Welcome/Welcome.js";
import XRiddle from "views/XRiddle/XRiddle.js";

const AppRouter = props => {
	const { user, isCheckingAuthStatus, updateUserProgress } = useContext(AuthContext);
	const history = useHistory();

	// handle redirecting to next step by checking the users progress
	useAsyncEffect(async () => {
		if (!isCheckingAuthStatus) {
			if (user) {
				let redirectPath;
				if (user.role === ROLE_RIDDLER) {
					if (user.progress.length !== 0) {
						const currentStep = getCurrentStep(user.progress);
						// advance here because the url was changed manually as solution of the riddle
						if (
							history.location.pathname === X_RIDDLE_ROUTE &&
							currentStep.current_step_id === 1 &&
							!currentStep.completed
						) {
							const newProgress = await advanceProgress(2);
							await updateUserProgress(newProgress.data);
						} else {
							redirectPath = getRouteByStepId(currentStep.current_step_id);
						}
						if (redirectPath === FRIENDS_RIDDLE_ROUTE) {
							redirectPath += `?token=${user.invitation_token}`;
						}
					} else {
						// no progress exists, so create it and redirect
						const newProgress = await advanceProgress(1);
						await updateUserProgress(newProgress.data);
					}
				} else if (user.role === ROLE_PARTY_GUEST) {
					redirectPath = FRIENDS_ASSIST_ROUTE;
				}

				if (redirectPath) {
					history.push(redirectPath);
				}
			}
		}
	}, [isCheckingAuthStatus, user, history.location.pathname]);

	return !isCheckingAuthStatus ? (
		<Layout>
			{user ? (
				<Switch>
					<Route exact path={URL_RIDDLE_ROUTE} component={Welcome} />
					<Route exact path={COOKIE_RIDDLE_ROUTE} component={CookieRiddle} />
					<Route exact path={TIMEOUT_RIDDLE_ROUTE} component={TimeOutRiddle} />
					<Route exact path={X_RIDDLE_ROUTE} component={XRiddle} />
					<Route exact path={FRIENDS_RIDDLE_ROUTE} component={PartyRiddle} />
					<Route exact path={FRIENDS_ASSIST_ROUTE} component={PartyGuest} />
                    <Route exact path={FINAL_SCREEN_ROUTE} component={FinalScreen} />
					<Route
						render={() => {
							return <Redirect to={HOME_ROUTE} />;
						}}
					/>
				</Switch>
			) : (
				<Switch>
					<Route exact path={LOGIN_WITH_TOKEN_ROUTE} component={LoginWithToken} />
					<Route exact path={HOME_ROUTE} component={Home} />
					<Route
						render={() => {
							let redirectRoute = HOME_ROUTE;
							// nasty hack to handle race condition, because this route for unathorized users
							if (history.location.pathname === FRIENDS_RIDDLE_ROUTE) {
								const params = parse(history.location.search);
								redirectRoute = LOGIN_WITH_TOKEN_ROUTE + "?token=" + params.token;
							}
							return <Redirect to={redirectRoute} />;
						}}
					/>
				</Switch>
			)}
		</Layout>
	) : (
		""
	);
};

AppRouter.propTypes = {};

export default AppRouter;
