// Home
export const HOME_ROUTE = "/";
export const URL_RIDDLE_ROUTE = "/indias-17th-birthday";
export const X_RIDDLE_ROUTE = "/indias-18th-birthday";
export const COOKIE_RIDDLE_ROUTE = "/nothing-to-see-here";
export const TIMEOUT_RIDDLE_ROUTE = "/nothing-to-see-here-as-well";
export const FRIENDS_RIDDLE_ROUTE = "/invite-your-guests-with-this-link";
export const FINAL_SCREEN_ROUTE = "/celebrate";
export const FRIENDS_ASSIST_ROUTE = "/party-guest";

// Auth
export const LOGOUT_ROUTE = "/logout";
export const LOGIN_WITH_TOKEN_ROUTE = "/bounce";

