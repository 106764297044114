import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.scss';
import Field from "../Field/Field";

const Input = React.forwardRef(({className, type = 'text', ...other}, ref) => {
    const classNames = useMemo(()=>[styles.Input, className], [className]);
    return (
        <Field type={type} className={classNames} {...other} ref={ref}/>
    );
});

Input.propTypes = {
    // label: PropTypes.string,
    // placeholder: PropTypes.string,
    // type: PropTypes.oneOf(['text', 'password'])
};

export default React.memo(Input);
