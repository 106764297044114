import React from 'react';
import { useStopwatch } from "react-timer-hook";
import styles from './TimerStateful.module.scss';

const TimerStateful = () => {
    const { seconds, minutes, hours } = useStopwatch({ autoStart: true });
    const minutesFormatted = minutes.toString().padStart(2,"0");
    const secondsFormatted = seconds.toString().padStart(2,"0");
    const hoursFormatted = hours.toString().padStart(2,"0");
    return (
        <div className={styles.TimerStateful}>
            <span>{minutesFormatted}</span><span>:</span>
            <span>{secondsFormatted}</span>
        </div>
    );
};

TimerStateful.propTypes = {

};

export default TimerStateful;
