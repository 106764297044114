import Content from "components/Layout/Content/Content.js";
import TimerStateful from "components/TimerStateful/TimerStateful.js";
import FatHeadline from "components/Typography/FatHeadline/FatHeadline.js";
import React from "react";
import styles from "./Welcome.module.scss";
import FlexBoxWrapper from "components/Layout/Wrappers/FlexBoxWrapper.js";

const Welcome = props => {
	return (
		<>
			<Content className={styles.Text}>
                <TimerStateful/>
				<FlexBoxWrapper justifyContent={"center"} direction={"column"}>
						<FatHeadline>happy 17th!</FatHeadline>
						<p style={{ width: "40%" }}>
							Liebe India, alles Beste zu Deinem 17. Geburtstag. Hoffentlich hast Du trotz all der
							nervigen Dinge, die uns aktuell so plagen (Corona, anstehende Abschlussprüfungen,{" "}
							<span className={styles.selectHighlight}>falsche URLs</span>) einen schönen Tag. Leider
							können wir mal wieder nicht vorbei kommen, um Dir ein schickes Geschenk zu übergeben, aber
							dafür planen wir für's nächste Jahr was Großes! Freu Dich schon mal
							auf knackigen Knobelspaß.
						</p>
						<p>Bis dahin,<br/>  Jessie, Paul, Marlene und Elli</p>
				</FlexBoxWrapper>
			</Content>
			<Content minWidth={0} minHeight={0} className={styles.SizeWarning}>
				<h1>:/</h1>
                <p>
					Leider ist Dein Browserfenster für diese Website zu klein. Zeit für den Umzug an einen Laptop!
				</p>
                <p>
                    Und dort wäre es gut, wenn Du Chrome benutzen könntest - bei allen anderen Browsern kann ich nicht garantieren, dass hier alles funktioniert...
                </p>
			</Content>
		</>
	);
};

Welcome.propTypes = {};

export default Welcome;
