import classNames from "classnames";
import React from 'react';
import styles from './Content.module.scss';

const Content = ({withInnerWrapper=true, children, minWidth='1400px', minHeight="900px", className, innerWrapperClassName}) => {
    return (<main className={classNames(styles.Content, className)} style={{minWidth:minWidth, minHeight:minHeight}}>
        {withInnerWrapper ? (
            <div className={classNames(styles.InnerWrapper, innerWrapperClassName)}>{children}</div>
        ) : children}
    </main>);
};

export default Content;
