import { advanceProgress } from "api/loaders/userProgressLoaders.js";
import classNames from "classnames";
import Modal from "components/Modal/Modal.js";
import TimerStateful from "components/TimerStateful/TimerStateful.js";
import FatHeadline from "components/Typography/FatHeadline/FatHeadline.js";
import { AuthContext } from "contexts/AuthContext";
import { useContainerDimensions } from "hooks/useContainerDimensions.js";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./XRiddle.module.scss";
const XRiddle = props => {
	const WrapperRef = useRef(null);
	const dimensions = useContainerDimensions(WrapperRef);
	const { updateUserProgress } = useContext(AuthContext);
	const [targetIsVisible, setTargetIsVisible] = useState();
	const [showSuccessModal, setShowSuccessModal] = useState();
	const [showHole, setShowHole] = useState(false);
	const [showSmallWindowContent, setShowSmallWindowContent] = useState(null);

	const handleModalClose = async () => {
		const newProgress = await advanceProgress(3);
		updateUserProgress(newProgress.data);
	};

	const handleTargetClick = () => {
		setShowSuccessModal(true);
	};

	useEffect(() => {
		const width = dimensions.width;
		const height = dimensions.height;

		if (width && showSmallWindowContent === null) {
			setShowSmallWindowContent(width < 1400);
		} else if (showSmallWindowContent === true && width > 1400) {
			setShowSmallWindowContent(false);
		}

		if (width < 639 && width > 612 && height < 487 && height > 466) {
			setTargetIsVisible(true);
		} else {
			setTargetIsVisible(false);
		}
	}, [dimensions, showSmallWindowContent]);

	const handleXClick = () => {
		setShowHole(true);
	};

	const targetButtonStyles = classNames(styles.TargetButton, { [styles.front]: targetIsVisible });

	const textContent = useMemo(() => {
		if (!showHole) {
			return (
				<>
					<p>
						Endlich ist es soweit, der große Tag ist da. Und wie angekündigt, kommt hier das große Rätsel
						(naja, eigentlich bist Du ja schon mittendrin...). Aufgebaut ist das Ganze wie eine Art
						Escape-Room (falls Du sowas noch nicht gemacht hast, solltest Du das dringend nachholen). Auf
						jeder Seite gilt es ein Rätsel zu lösen, dessen Lösung auf den ersten Blick meist nicht so klar
						ist. Und weil ich nun mal 'n Computer-Heini bin, sind auch die Lösungen teilweise recht nerdig
						(und nicht so einfach wie "{" "}
						<span className={styles.xInText} onClick={handleXClick}>
							X
						</span>{" "}
						marks the spot"). Falls Du mal gar nicht weiterkommst, kannst Du mich natürlich kontaktieren
						- aber wirklich nur im äußersten Notfall.{" "}
					</p>
					<p>Jetzt aber viel Spaß!</p>
				</>
			);
		}

        return <p>Sehr gut! Ein X hast Du schon mal gefunden, vielleicht findest Du ja noch eines...</p>;
	}, [showHole]);

	return (
		<>
			<TimerStateful/>
			<div className={styles.Background}>
				<div className={targetButtonStyles} onClick={handleTargetClick}>
					x
				</div>
			</div>

			<div className={styles.Wrapper} ref={WrapperRef}>
				{showSmallWindowContent === false ? (
					<>
						<div
							style={{
								padding: "2rem",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							<FatHeadline>happy 18th!</FatHeadline>
							{textContent}
						</div>
						<div />
						<div />
						{showHole ? <div className={styles.image} /> : <div />}
					</>
				) : (
					<div className={styles.SizeWarning}>
						<h2>Zu klein ist das Fenster...</h2>
						<p>Um diese Rätsel zu lösen, muss Du es (erstmal) größer ziehen.</p>
					</div>
				)}
			</div>
			<Modal show={showSuccessModal} onClose={handleModalClose}>
				Gut gesucht!
			</Modal>
		</>
	);
};

export default XRiddle;
