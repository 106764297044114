import classNames from "classnames";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./Face.module.scss";

const Face = ({mood, bodyPartBaseStyle}) => {
    const faceClasses = classNames(bodyPartBaseStyle, styles[mood], styles.Face);

    return (
        <div className={faceClasses}>
            <div className={classNames(styles.Eyes, bodyPartBaseStyle)}/>
            <div className={classNames(styles.Mouth, bodyPartBaseStyle)}/>
        </div>
    );
};

Face.propTypes = {

};

export default Face;
