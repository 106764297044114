import classNames from "classnames";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Arm from "views/PartyRiddle/India/Arm/Arm.js";
import { GIFT_GLASS_NAME, GIFT_HAT_NAME, GIFT_PRESENT_NAME } from "views/PartyRiddle/India/constants.js";
import Face from "views/PartyRiddle/India/Face/Face.js";
import Friend from "views/PartyRiddle/India/Friend/Friend.js";
import styles from "./India.module.scss";

const India = ({ partyGuests, maxNumberOfGuests, onRiddleSolved }) => {
	const [mood, setMood] = useState("neutral");
	const [leftArmUp, setLeftArmUp] = useState(false);
	const [rightArmUp, setRightArmUp] = useState(false);
	const [giftNameLeft, setGiftNameLeft] = useState(null);
	const [giftNameTop, setGiftNameTop] = useState(null);
	const [giftNameRight, setGiftNameRight] = useState(null);
    const [numberOfCorrectGifts, setNumberOfCorrectGifts] = useState(0);

	useEffect(() => {
		let mood = "neutral";
		let giftNameLeft = null;
		let giftNameTop = null;
		let giftNameRight = null;
		let giftLeftIsCorrect = false;
		let giftTopIsCorrect = false;
		let giftRightIsCorrect = false;
        let correctGifts = 0;
        if (partyGuests.length === maxNumberOfGuests) {
            for (let i = 0; i < 3; i++) {
                if (partyGuests[i]) {
                    switch (i) {
                        case 0:
                            giftNameLeft = partyGuests[0].presentName;
                            if (giftNameLeft === GIFT_GLASS_NAME) {
                                giftLeftIsCorrect = true;
                                correctGifts++;
                            }
                            break;
                        case 1:
                            giftNameTop = partyGuests[1].presentName;
                            if (giftNameTop === GIFT_HAT_NAME) {
                                giftTopIsCorrect = true;
                                correctGifts++;
                            }
                            break;
                        case 2:
                            giftNameRight = partyGuests[2].presentName;
                            if (giftNameRight === GIFT_PRESENT_NAME) {
                                giftRightIsCorrect = true;
                                correctGifts++;
                            }
                            break;
                    }
                }
            }
        }
		setGiftNameLeft(giftNameLeft);
		setGiftNameTop(giftNameTop);
		setGiftNameRight(giftNameRight);
		setLeftArmUp(giftRightIsCorrect);
		setRightArmUp(giftLeftIsCorrect);

		if (giftRightIsCorrect && giftLeftIsCorrect && giftTopIsCorrect) {
			mood = "happy";
		} else if (giftNameTop && !giftTopIsCorrect) {
			mood = "annoyed";
		}
		setMood(mood);
        setNumberOfCorrectGifts(correctGifts)
	}, [partyGuests]);


    useEffect(() => {
        if(numberOfCorrectGifts === maxNumberOfGuests){
            onRiddleSolved();
        }
    }, [numberOfCorrectGifts]);

    return (
		<div className={styles.Wrapper}>
			<div className={styles.BodyWrapper}>
				<Face bodyPartBaseStyle={styles.BodyElement} mood={mood} />
				<Arm side={"left"} isUp={leftArmUp} bodyPartBaseStyle={styles.BodyElement} />
				<Arm side={"right"} isUp={rightArmUp} bodyPartBaseStyle={styles.BodyElement} />
				<div className={classNames(styles.Body, styles.BodyElement)} />
				{partyGuests.length === maxNumberOfGuests && (
					<>
						<Friend position={"top"} giftName={giftNameTop} />
						<Friend position={"left"} giftName={giftNameLeft} />
						<Friend position={"right"} giftName={giftNameRight} />
					</>
				)}
			</div>
		</div>
	);
};

India.propTypes = {};

export default India;
