import React, { useEffect, useState } from "react";
export const useContainerDimensions = myRef => {
	const getDimensions = () => {
		const {left, top, width, height} = myRef.current.getBoundingClientRect();
		return {left, top, width, height};
	};

	const [dimensions, setDimensions] = useState({});

	useEffect(() => {
		const handleResize = () => {
			setDimensions(getDimensions())
		};

		if (myRef.current) {
			setDimensions(getDimensions())
		}

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [myRef]);

	return dimensions;
};
