import axiosApi from "api/axiosApi.js";

export const ACCESS_TOKEN_KEY = "token";
const REFRESH_TOKEN_KEY = "refreshToken";


export const getAccessToken = () => {
	return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
	return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const deleteTokens = ()=>{
	axiosApi.defaults.headers.common["Authorization"] = "";
	localStorage.removeItem(ACCESS_TOKEN_KEY);
	localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const setTokens = (tokenObject) => {
    axiosApi.defaults.headers.common["Authorization"] = "Bearer " + tokenObject[ACCESS_TOKEN_KEY];
	localStorage.setItem(ACCESS_TOKEN_KEY, tokenObject[ACCESS_TOKEN_KEY]);
	localStorage.setItem(REFRESH_TOKEN_KEY, tokenObject[REFRESH_TOKEN_KEY]);
};
