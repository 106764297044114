import classNames from "classnames";
import Button from "components/Button";
import Content from "components/Layout/Content/Content.js";
import FlexBoxWrapper from "components/Layout/Wrappers/FlexBoxWrapper.js";
import { APP_URL, PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from "constants/env.js";
import { AuthContext } from "contexts/AuthContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import PropTypes from "prop-types";
import { GIFT_GLASS_NAME, GIFT_HAT_NAME, GIFT_PRESENT_NAME } from "views/PartyRiddle/India/constants.js";
import styles from "./PartyGuest.module.scss";
Pusher.Runtime.createXHR = function () {
	var xhr = new XMLHttpRequest();
	xhr.withCredentials = true;
	return xhr;
};

const PartyGuest = props => {
	const { user } = useContext(AuthContext);
	const [messageChannel, setMessageChannel] = useState(null);
	const [cannotJoinMessage, setCannotJoinMessage] = useState(null);
	const [selectedGiftName, setSelectedGiftName] = useState(null);
    const [partyHasStarted, setPartyHasStarted] = useState(false);
	// @ToDo Handle channel occupied message
	useEffect(() => {
		// Pusher.logToConsole = true;
		const pusher = new Pusher(PUSHER_APP_KEY, {
			cluster: PUSHER_APP_CLUSTER,
			authEndpoint: APP_URL + "api/broadcasting/auth",
		});

		const channel = pusher.subscribe("private-friends-help-" + user.invited_by);

		channel.bind("client-channel-full", data => {
			if (data.userId === user.id) {
				channel.unsubscribe("private-friends-help");
				setCannotJoinMessage("Auf der Party sind bereits genug Gäste, du kannst leider nicht mehr rein...");
			}
		});

		channel.bind("client-host-joined", data => {
			channel.trigger("client-user-joined", { userId: user.id });
		});

        channel.bind("client-party-status", data=>{

            setCannotJoinMessage(data.partyStarted === false ? 'Waiting for the host to let you enter.':null)
        })

		channel.bind("pusher:subscription_succeeded", () => {
			setMessageChannel(channel);
			channel.trigger("client-user-joined", { userId: user.id });
		});

		return () => {
			channel.trigger("client-user-left", { userId: user.id });
			channel.unsubscribe();
		};
	}, [user, setPartyHasStarted]);

	const sendPresent = useCallback(
		giftName => {
			messageChannel.trigger("client-send-gift", { userId: user.id, presentName: giftName });
			setSelectedGiftName(giftName);
		},
		[messageChannel, user.id],
	);

	return (
		<Content minWidth={0} minHeight={"200px"} innerWrapperClassName={styles.InnerWrapper}>
			<FlexBoxWrapper justifyContent={"center"} direction={"column"}>
				<div>
					<div className={styles.Headline}>Welcome to the party!</div>
					<p className={styles.Message}>{cannotJoinMessage || "Time to hand over your gift"}</p>
                    {cannotJoinMessage=== null &&
					<><div
						className={classNames(styles.button, styles.hat, {
							[styles.selected]: selectedGiftName === GIFT_HAT_NAME,
						})}
						onClick={() => {
							sendPresent(GIFT_HAT_NAME);
						}}
					/>
					<div
						className={classNames(styles.button, styles.glass, {
							[styles.selected]: selectedGiftName === GIFT_GLASS_NAME,
						})}
						onClick={() => {
							sendPresent(GIFT_GLASS_NAME);
						}}
					/>
					<div
						className={classNames(styles.button, styles.present, {
							[styles.selected]: selectedGiftName === GIFT_PRESENT_NAME,
						})}
						onClick={() => {
							sendPresent(GIFT_PRESENT_NAME);
						}}
					/></>}
				</div>
			</FlexBoxWrapper>
		</Content>
	);
};

PartyGuest.propTypes = {};

export default PartyGuest;
